
          @import "@/styles/variables.scss";
        
.user-grade-intro-wrapper {
  p.first {
    margin-top: 0;
  }
  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    thead {
      th {
        text-align: left;
        background-color: $light;

        &.grade {
          width: 120px;
        }

        &.condition {
        }

        &.description {
        }
      }
    }

    th,
    td {
      padding: 1rem;
      border: 1px solid $border-color;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  ul.info {
    display: none;
    list-style: none;
    padding-left: 0;
    margin-top: 1rem;

    .grade-info {
      font-weight: bold;
    }

    .condition {
      margin-bottom: 0.5rem;
      > .tag {
        align-self: flex-start;
      }
      ul {
        list-style: disc;
        padding-left: 1rem;
      }
    }

    .description {
    }

    .tag {
      display: inline-block;
      font-size: 14px;
      padding: 4px;
      background-color: $gray-200;
      border-radius: 5px;
      margin-right: 0.5rem;
      min-width: 40px;
      text-align: center;
    }

    .text {
      flex-grow: 1;
    }

    > li {
      margin-bottom: 1rem;
      border-bottom: 1px solid $border-color;
      padding-bottom: 1rem;

      &:first-child {
        border-top: 1px solid $border-color;
        padding-top: 1rem;
      }
      > div {
        display: flex;
        margin-bottom: 0.5rem;

        > span {
          align-self: flex-start;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    @media (max-width: 768px) {
      display: block;
    }
  }

  .warning {
    color: $warning;
    font-size: 14px;
  }
}
