
          @import "@/styles/variables.scss";
        
.naver-map-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1rem;
  z-index: 999;

  > div {
    background-color: $white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid $border-color;

    > div.title-content {
      padding: 1rem;
      display: flex;
      line-height: 0;

      > div {
        align-self: center;
        line-height: 0;

        &.title {
          flex-grow: 1;
        }
      }
    }

    > div.map-content {
      flex-grow: 1;
      padding: 0 0.5rem;
      position: relative;
      > div {
        width: 100%;
        height: 100%;
        border: 1px solid $border-color;
      }

      .go-current-position {
        position: absolute;
        top: 8px;
        right: 24px;
        z-index: 999;
        padding: 4px 8px;
        cursor: pointer;
        color: $blue;
        font-weight: bold;
        &:hover {
          border-bottom: 1px solid $black;
        }
      }
    }

    > div.map-button {
      padding: 1rem;
      text-align: center;

      > button {
        margin-right: 0.5rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
