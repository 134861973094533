
          @import "@/styles/variables.scss";
        
.radio-button-wrapper {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;

  button {
    border: none;
    outline: none;
    background-color: $white;
    text-align: center;
    height: 100%;
    display: inline-block;
    vertical-align: top;
    color: $black;
    margin-right: 0;
    border-radius: 0 !important;
    border-right-style: none !important;
    position: relative;

    &.btn {
    }

    &:first-child {
      border-radius: 2px 0 0 2px !important;
      .liner {
        border-radius: 2px 0 0 2px;
      }
    }

    &:last-child {
      margin-right: 0;
      border-right-style: solid !important;
      border-radius: 0 2px 2px 0 !important;
      .liner {
        border-radius: 0 2px 2px 0;
      }
    }

    &.rounded {
      margin-right: 0.25rem;
      border-radius: 50% !important;
    }

    &:focus {
      outline: none;
    }

    .liner {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      border: 1px solid rgba(0, 0, 0, 0.7);
      opacity: 0;
      transition: 0.3s opacity;
      width: calc(100% + 2px) !important;
      z-index: 2;
    }

    &.rounded {
      .liner {
        border-radius: 50%;
      }
    }

    &:hover {
      .liner {
        opacity: 1;
        transition: 0.3s opacity;
      }
      @media (min-width: 992px) {
        background-color: darken($light, 2%);
        color: $black;
      }
    }

    &.selected {
      background-color: $yellow !important;
      color: $black !important;
      &:hover {
        @media (min-width: 992px) {
          background-color: lighten($yellow, 5%) !important;
        }
      }
    }
  }
}
