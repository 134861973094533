
          @import "@/styles/variables.scss";
        
.title-wrapper {
  &.h1 {
    font-size: 22px;
  }

  &.h2 {
    font-size: 19px;
  }

  &.h3 {
    font-size: 16px;
  }

  &.h4 {
    font-size: 13px;
  }

  &.h5 {
    font-size: 10px;
  }
}
