
          @import "@/styles/variables.scss";
        
.tag-selector-wrapper {
  height: 50px;
  position: relative;
  width: 100%;

  > div {
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    ul {
      align-self: center;
      list-style: none;
      padding: 0 0 6px;
      margin: 0;
      width: 100%;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background-color: #f8f8f8;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #d4d4d4;
        border-radius: 4px;
      }

      li {
        display: inline-block;
        font-size: 13px;
        border: 1px solid #d1d1d1;
        margin-right: 6px;
        padding: 6px 10px;
        border-radius: 12px;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);
        cursor: pointer;
        background-color: $white;

        &.selected {
          background-color: $color-point;
        }

        &.clear-button {
          vertical-align: top;
          position: relative;
          padding-right: 24px;
          .icon {
            margin-right: 4px;
          }

          .count {
            position: absolute;
            right: 4px;
            display: inline-flex;
            background-color: $color-sub1;
            width: 15px;
            height: 15px;
            text-align: center;
            border-radius: 50%;
            font-size: 10px;
            justify-content: center;
            color: white;
            > span {
              align-self: center;
            }
          }
        }
      }
    }
  }
}
