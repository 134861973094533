
          @import "@/styles/variables.scss";
        
.club-info-list-wrapper {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  > div {
    padding: 0 1rem 1rem;

    ul {
      align-self: center;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-size: 13px;
        box-sizing: border-box;
        padding-bottom: 1rem;
        padding-top: 1rem;
        display: flex;
        border-bottom: 1px solid $gray-200;

        > div {
          align-self: center;
        }

        .club-image {
          margin-right: 12px;
          width: 36px;
          height: 36px;
          img {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            border-radius: 50%;
          }
        }

        .name {
          font-size: 18px;
          flex-grow: 1;

          .paused {
            display: inline-block;
            font-size: 12px;
            background-color: $danger;
            color: $white;
            padding: 2px 4px;
            border-radius: 5px;
            margin-left: 0.5rem;
          }
        }

        .location {
        }
      }
    }
  }
}
