
          @import "@/styles/variables.scss";
        
.club-wrapper {
  .label {
    _height: 14px !important;
  }
  .page-title {
  }

  .club-icon {
    justify-content: center;
    display: flex;
    height: 80px;
    > div {
      align-self: center;
      text-align: center;

      .icon {
        font-size: 30px;
      }
    }
  }

  .club-image {
    margin-top: 1rem;
    margin-bottom: 2rem;
    justify-content: center;

    > div {
      text-align: center;
      line-height: 0;

      img {
        line-height: 100%;
        border: 1px solid $border-color;
        max-width: 200px;
        border-radius: 5px;
      }
    }
  }

  .warn {
    color: $danger;
  }
}
