
          @import "@/styles/variables.scss";
        
.cs-list-wrapper {
  ul {
    align-self: center;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      box-sizing: border-box;
      display: flex;
      border-bottom: 1px solid $gray-200;

      &:first-child {
        border-top: 1px solid $gray-200;
      }

      > div {
        padding: 0.75rem 0.5rem;
      }

      .subject {
        flex-grow: 1;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        a {
          text-decoration: none;
        }
      }

      .info {
        min-width: 90px;
        text-align: right;
        > span {
          white-space: nowrap;
          font-size: 14px;
          display: inline-block;
          line-height: 100%;
          padding: 3px 6px;
          background-color: $gray-200;
          border-radius: 2px;
          margin-right: 4px;

          &:last-child {
            margin-right: 0;
          }

          &.answered {
            background-color: $yellow;
          }
        }
      }
    }
  }
}
