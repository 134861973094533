
          @import "@/styles/variables.scss";
        
.label-wrapper {
  display: inline-flex;
  margin-right: 1rem;
  color: $gray-600;
  @media (max-width: 768px) {
    display: flex;
    margin-right: 0;
    > span {
      align-self: center;
    }
  }

  &.size-xxl {
    font-size: 20px;
    height: $xxl-height;

    > span {
      .required {
        font-size: 10px;
        top: -2px;
        right: -14px;
      }
    }
  }

  &.size-xl {
    font-size: 20px;
    height: $xl-height;

    > span {
      .required {
        font-size: 10px;
        top: -2px;
        right: -14px;
      }
    }
  }

  &.size-lg {
    font-size: 18px;
    height: $lg-height;

    > span {
      .required {
        font-size: 10px;
        top: -2px;
        right: -14px;
      }
    }
  }

  &.size-md {
    font-size: 16px;
    height: $md-height;

    > span {
      .required {
        font-size: 10px;
        top: -2px;
        right: -14px;
      }
    }
  }

  &.size-sm {
    font-size: 14px;
    height: $sm-height;

    > span {
      .required {
        font-size: 10px;
        top: -2px;
        right: -14px;
      }
    }
  }

  &.size-xs {
    font-size: 12px;
    height: $xs-height;

    > span {
      .required {
        font-size: 10px;
        top: -2px;
        right: -14px;
      }
    }
  }

  > span {
    position: relative;
    align-self: center;

    .required {
      position: absolute;
      font-size: 10px;
      top: -2px;
      right: -14px;
      color: $warning;
    }

    &.liner {
      flex-grow: 1;
      text-align: right;

      @media (max-width: 768px) {
        display: none;
      }
      > span {
        display: inline-block;
        height: 10px;
        width: 1px;
        background-color: $gray-500;
      }
    }
  }
}
