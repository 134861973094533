
          @import "@/styles/variables.scss";
        
.holidays-wrapper {
  > div {
    div.content {
      .title {
        font-weight: bold;
        margin-bottom: 0.5rem;

        &.second {
          margin-top: 1rem;
        }
      }

      ul {
        align-self: center;
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          box-sizing: border-box;
          display: flex;
          border-bottom: 1px solid $gray-200;

          > div {
            padding: 0.75rem 0.5rem;
          }

          .regular-yn {
          }

          .date {
            width: 140px;
          }

          .name {
            flex-grow: 1;
          }
        }
      }
    }
  }
}
