
          @import "@/styles/variables.scss";
        
.users-wrapper {
  .tab {
    margin: 0;
    width: 100%;
    height: 30px;

    @media (max-width: 768px) {
      margin: 0;
    }
  }
}
