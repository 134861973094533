
          @import "@/styles/variables.scss";
        
.liner-wrapper {
  align-self: center;
  > div {
    background-color: $white;
  }

  &.color-white {
    > div {
      background-color: $white;
    }
  }

  &.color-gray {
    > div {
      background-color: rgba(0, 0, 0, 0.4);
    }
  }

  &.color-black {
    > div {
      background-color: $black;
    }
  }

  &.color-light {
    > div {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &.color-rainbow {
    > div {
      background: linear-gradient(90deg, #7a7a7c 0%, #ff4400 20%, #6fcd11 40%, #2a3cbc 60%, #e21550 80%, #eefb0a 100%);
      opacity: 0.4;
    }
  }
}
