
          @import "@/styles/variables.scss";
        
.check-box-wrapper {
  position: relative;
  vertical-align: middle;
  align-self: center;
  justify-content: center;
  padding: 0;
  cursor: pointer;
  display: inline-flex;

  label {
    margin: 0;
    vertical-align: middle;
    align-self: center;
    justify-content: center;
    transition: all 0.3s;
    cursor: pointer;
    display: inline-block;
    font-family: pretendard, sans-serif;
    line-height: 100%;
  }

  .check-box-span {
    border: 1px solid $gray-600;
    display: inline-block;
    vertical-align: middle;
    align-self: center;
    justify-content: center;
    margin-right: 5px;
    transition: all 0.3s;
    cursor: pointer;
    border-radius: 2px;

    > span {
      display: inline-block;
      width: 100%;
      height: 100%;
      transition: all 0.3s;
      border-radius: 2px;
    }
    line-height: 0;
    padding: 2px;

    &.checked {
      > span {
        background-color: $yellow;
        transition: all 0.3s;
        border: 1px solid rgba(0, 0, 0, 0.4);
      }
    }
  }

  &.lg {
    label {
      font-size: 1rem;
    }

    .check-box-span {
      width: 17px;
      height: 17px;
    }
  }

  &.md {
    height: 38px;

    label {
      font-size: 15px;
    }

    .check-box-span {
      width: 16px;
      height: 16px;
      position: relative;
      top: -1px;
    }
  }

  &.sm {
    height: 31px;
    line-height: 0;
    label {
      font-size: 14px;
    }

    .check-box-span {
      width: 16px;
      height: 16px;
    }
  }

  &:hover {
    .check-box-span {
      border-color: $black;
      transition: all 0.3s;
    }
  }
}
