
          @import "@/styles/variables.scss";
        
.customer-service-wrapper {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    @media (max-width: 768px) {
      text-align: center;
    }

    li {
      display: inline-block;
      padding: 0.75rem 2rem 0.5rem;

      border: 1px solid $border-color;
      border-radius: 5px;
      margin-right: 1rem;
      width: 120px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      .icon {
        font-size: 22px;
        text-align: center;

        > span {
          display: inline-flex;
          width: 40px;
          height: 40px;
          background-color: #333;
          color: $white;
          border-radius: 50%;
          justify-content: center;
          line-height: 0;

          i {
            align-self: center;
            line-height: 0;
          }
        }
      }

      &.enabled:hover {
        .icon {
          > span {
            background-color: $yellow;
            color: $black;
          }
        }
      }

      &.disabled {
        opacity: 0.4;
        cursor: text;
      }

      .line {
        > div {
          height: 1px;
          background-color: $gray-400;
          margin: 0.5rem -1rem;
        }
      }

      .text {
        text-align: center;
        font-size: 14px;
        position: relative;
      }
    }
  }
}
