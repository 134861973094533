
          @import "@/styles/variables.scss";
        
.input-wrapper {
  display: block;
  position: relative;
  width: 100%;
  border: 1px solid $border-color;
  padding: 8px;
  height: auto;
  z-index: 2;
  background-color: $white;
  border-radius: 2px;

  &:focus {
    box-shadow: none;
    outline: none;
  }

  &:invalid {
    border-bottom: 1px solid $danger;
  }

  &[type='range'] {
    padding: 0;
  }

  &.size-xxl {
    font-size: 20px;
    height: $xxl-height;
  }

  &.size-xl {
    font-size: 20px;
    height: $xl-height;
  }

  &.size-lg {
    font-size: 18px;
    height: $lg-height;
  }

  &.size-md {
    font-size: 16px;
    height: $md-height;
  }

  &.size-sm {
    font-size: 14px;
    height: $sm-height;
  }

  &.size-xs {
    font-size: 12px;
    height: $xs-height;
  }

  &.disabled {
    background-color: $gray-100;
    opacity: 0.6;
  }

  input {
    border: 1px solid $input-border-color;
    padding: 4px 7px 3px 7px;
    -webkit-appearance: none;

    &:focus {
      border-color: $primary;
    }
  }
}
