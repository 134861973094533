
          @import "@/styles/variables.scss";
        
.edit-club-wrapper {
  .image-info {
    flex-grow: 1;
    .image-preview {
      text-align: center;
      line-height: 0;
      margin-bottom: 1rem;
      img {
        line-height: 100%;
        border: 1px solid $border-color;
        max-width: 200px;
        border-radius: 5px;
      }
    }

    .image-delete-button {
      text-align: center;
      margin-bottom: 0.5rem;
    }
  }

  form {
    padding: 0;
  }
}
