
          @import "@/styles/variables.scss";
        
.header-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 55px;
  box-sizing: border-box;
  z-index: 991;
  transition: top 0.3s;
  background-color: $yellow;

  @media (max-width: 768px) {
    _background: linear-gradient($yellow 0%, rgba(255, 255, 255, 0) 100%);
    background-color: $white;

    display: none;
    &.is-main {
      display: block;
    }
  }

  > div {
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    height: 100%;

    > div {
      &.right-buttons {
        display: flex;
        margin-right: 0.25rem;

        .short-cuts-button {
          display: flex;

          .grade-info {
            display: inline-flex;
            font-size: 12px;
            > div {
              align-self: center;
            }

            .user-grade {
              margin-right: 0.5rem;
              white-space: nowrap;
              text-align: center;

              a {
                text-decoration: none;
                span {
                  background-color: #333;
                  color: $white;
                  padding: 4px;
                  border-radius: 5px;
                }
              }
            }
          }

          > a {
            align-self: center;
            text-decoration: none;
            padding: 0 10px;
            color: $blue;
            font-size: 14px;
            position: relative;

            &:visited {
              color: $blue;
            }

            &.login-icon {
              font-size: 22px;
              color: $black;
              line-height: 0;
              &:visited {
                color: $black;
              }

              @media (max-width: 768px) {
                display: none;
              }
            }
          }

          > button {
            align-self: center;
            background-color: transparent;
            outline: none;
            border: none;
            font-size: 22px;
            padding: 0 0.75rem;
            color: $black;
            cursor: pointer;
            position: relative;

            .refresh-interval {
              position: absolute;
              left: 0;
              bottom: -12px;
              width: 100%;
              text-align: center;
              display: none;
              > span {
                display: inline-block;
                background-color: $gray-100;
                border: 1px solid $border-color;
                border-radius: 3px;
                font-size: 11px;
                white-space: nowrap;
                line-height: 100%;
                padding: 2px;
              }
            }
          }
        }

        .login-separator {
          display: none !important;

          @media (max-width: 768px) {
            display: inline-block !important;
          }
        }
      }

      &.menu-button {
        margin-right: 0.5rem;
        display: none;
        @media (max-width: 768px) {
          display: flex;
        }
        button {
          align-self: center;
          display: inline-flex;
          padding-left: 10px;
          padding-right: 10px;
          border: none;
          font-size: 20px;

          > i {
            align-self: center;
          }
        }
      }

      &.content {
        flex-grow: 1;

        .second-line {
          text-align: right;
        }
      }

      &.top-menu {
        flex-grow: 1;
        height: 100%;
        > div {
          display: flex;
          height: 100%;

          > div.admin-label {
            align-self: center;
            margin-left: 1rem;

            > span {
              background-color: $danger;
              font-size: 12px;
              color: $white;
              padding: 2px 4px;
              border-radius: 5px;
            }
          }

          > div.open-admin-menu {
            align-self: center;
            padding: 0 1rem;
            height: 100%;
            display: none;
            cursor: pointer;
            > div {
              align-self: center;
            }

            @media (max-width: 992px) {
              display: inline-flex;
            }
          }

          @media (max-width: 768px) {
            display: none;
          }

          ul {
            align-self: center;
            padding: 0 1rem;
            margin: 0;
            list-style: none;
            height: 100%;
            vertical-align: top;
            white-space: nowrap;

            &.admin-menu-list {
              @media (max-width: 992px) {
                display: none;

                &.open {
                  display: block;
                }
              }
            }

            > li {
              display: inline-flex;
              height: 100%;
              line-height: 100%;
              justify-content: center;
              margin: 0;
              vertical-align: top;
              transition: 0.5s padding;
              position: relative;

              .cursor {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                line-height: 0;
                text-align: center;
                display: none;
                > div {
                  width: 10px;
                  height: 10px;
                  background-color: white;
                  display: inline-block;
                  line-height: 0;
                  rotate: 45deg;
                  position: relative;
                  top: 6px;
                }
              }

              @media (min-width: 768px) {
                &:hover {
                  .cursor {
                    display: block;
                  }
                }
              }

              &.selected {
                > a {
                  background-color: $white;
                  padding: 0 1.5rem;
                  transition: 0.3s padding;
                }
              }

              &:last-child {
                display: none;
              }

              &.separator {
                min-width: auto;
                padding: 0;
              }

              > a {
                align-self: center;
                color: $black;
                text-decoration: none;
                line-height: 100%;
                height: 100%;
                display: inline-flex;
                padding: 0 0.5rem;

                &:active {
                  color: $black;
                }

                > div {
                  align-self: center;
                  div {
                    text-align: center;
                    line-height: 100%;

                    &.icon {
                      font-size: 12px;
                    }

                    &.text {
                      font-size: 14px;
                    }
                  }
                }
              }
            }

            &.admin-menu-list {
              @media (max-width: 992px) {
                position: relative;
                z-index: 1;
                padding: 0;
                top: 54px;
                width: 80px;
                left: -46px;

                @include shadow;

                &.open {
                  li {
                    display: block;
                    background-color: $white;
                    text-align: center;

                    border-left: 1px solid $border-color;
                    border-right: 1px solid $border-color;
                    border-bottom: 1px solid $border-color;
                    &:first-child {
                      border-top: 1px solid $border-color;
                    }

                    &:nth-child(1),
                    &:nth-child(3),
                    &:nth-child(5) {
                      border-bottom: none !important;
                    }

                    &:last-child {
                      border-bottom: 1px solid $border-color;
                    }

                    > a {
                    }

                    &.separator {
                      display: none !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.reserved {
        flex-grow: 1;
        display: none;

        @media (max-width: 768px) {
          display: block;
        }
      }
    }
  }

  &.hide {
    top: -55px;
    transition: top 0.3s;
  }

  .logo {
    height: 100%;
    min-width: 120px;
    position: relative;
    padding-top: 0;
    display: inline-flex;

    > a {
      align-self: center;
      line-height: 0;
      display: flex;
      color: $black;
      text-decoration: none;
      img {
        width: 40px;
        height: 40px;
        margin-left: 4px;
      }
      .gongtee {
        font-size: 16px;
        line-height: 100%;
        z-index: 2;
        text-align: left;
        display: inline-flex;

        > div {
          align-self: center;
          > div.name {
            font-weight: bold;
          }

          > div.slogan {
            font-size: 13px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .my-info-menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    > div {
      position: relative;
      max-width: $max-width;
      margin: 0 auto;

      > div {
        position: absolute;
        top: 45px;
        right: 8px;

        @media (max-width: 768px) {
          top: 45px;
          right: 32px;
        }
        background-color: $white;
        border: 1px solid $border-color;

        .arrow {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          text-align: right;
          padding-right: 11px;
          @media (max-width: 768px) {
            padding-right: 32px;
          }

          > div {
            display: inline-block;
            width: 11px;
            height: 11px;
            border: 1px solid $border-color;
            transform: rotate(45deg);
            position: relative;
            top: -8px;
            border-right: none;
            border-bottom: none;
            background-color: $white;
          }
        }

        ul {
          list-style: none;
          margin: 0;
          font-size: 14px;
          padding: 0.25rem 0;
          position: relative;
          z-index: 1;
          line-height: 100%;

          background-color: $white;
          li {
            line-height: 100%;
            a {
              color: $blue;
              text-decoration: none;
              padding: 0.5rem 1rem;
              display: inline-block;
              width: 100%;

              &:visited {
                color: $blue;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
