
          @import "@/styles/variables.scss";
        
.links-wrapper {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      margin-bottom: 1rem;
      a {
        text-decoration: none;
        color: $link-color;
      }
    }
  }
}
