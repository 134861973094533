
          @import "@/styles/variables.scss";
        
.batch-job-list-wrapper {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  > div {
    padding: 0 1rem 1rem;

    ul {
      align-self: center;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        box-sizing: border-box;
        padding-bottom: 1rem;
        padding-top: 1rem;
        display: flex;
        border-bottom: 1px solid $gray-200;

        > div {
          align-self: center;
        }

        .info {
          overflow: hidden;
          .name {
            font-size: 18px;
            margin-bottom: 0.125rem;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .class-name {
            font-size: 13px;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          flex-grow: 1;
        }

        .cron {
          font-size: 14px;
          white-space: nowrap;
          min-width: 80px;

          .expression {
            margin-bottom: 0.125rem;
          }

          span.status {
            display: inline-block;
            text-transform: uppercase;
            font-size: 11px;
            border-radius: 2px;
            &.active {
              background-color: $green;
              color: $white;
              padding: 2px 4px;
            }

            &.disabled {
              background-color: $gray-200;
              padding: 2px 4px;
            }
          }
        }

        .history {
          font-size: 14px;
          color: $blue;
          white-space: nowrap;
          display: inline-block;
          padding: 4px;
          min-width: 60px;
        }
      }
    }
  }
}
