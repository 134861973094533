
          @import "@/styles/variables.scss";
        
.user-info-list-wrapper {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  > div {
    padding: 0;

    ul {
      align-self: center;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        font-size: 13px;
        box-sizing: border-box;
        padding-bottom: 0.75rem;
        padding-top: 0.75rem;
        border-bottom: 1px solid $gray-200;

        > div {
          align-self: center;
        }

        &.disabled {
          .name {
            text-decoration: line-through;
          }
        }

        .name {
          display: flex;
          flex-grow: 1;
          margin-bottom: 0.125rem;

          > div {
            align-self: center;
          }

          .text {
            font-size: 18px;
          }

          .role {
            font-size: 12px;
            margin-left: 1rem;
            flex-grow: 1;
            text-align: right;

            > span {
              margin-right: 0.5rem;
              background-color: $danger;
              display: inline-block;
              border-radius: 4px;
              padding: 3px 6px;
              color: $white;
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        .vendor-info {
          display: flex;
          > div {
            align-self: center;
            display: flex;
            &.last {
              flex-grow: 1;
            }
            > div {
              align-self: center;
            }
          }

          @media (max-width: 768px) {
            display: block;
            > div {
              display: flex;

              .last-login-info {
                flex-grow: 1;
                display: flex;
                justify-content: flex-start;
                > div {
                  align-self: center;
                }
              }
            }
          }

          > div {
            align-self: center;
            padding: 3px;
          }

          .tag,
          .vendor {
            display: inline-block;
            border-radius: 4px;
            padding: 3px 6px;
            text-transform: uppercase;
            background-color: $gray-200;
            margin-right: 0.25rem;

            &.kakao {
              background-color: $kakao-color;
            }
          }

          .last-login-info {
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            > div {
              align-self: center;
            }
          }

          .grade {
            text-align: right;
            > span {
              display: inline-block;
              padding: 2px 4px;
              background-color: $gray-200;
              border-radius: 4px;
              width: 62px;
              text-align: center;
            }

            &.SILVER {
              > span {
                background-color: $gray-200;
              }
            }

            &.GOLD {
              > span {
                background-color: $yellow;
                color: $black;
              }
            }
          }
        }
      }
    }
  }
}
