
          @import "@/styles/variables.scss";
        
.image-upload-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  > div {
    flex-grow: 1;
    vertical-align: middle;
    align-self: center;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: $gray-100;
    border: 1px solid $border-color;
    padding: 24px 16px;

    .file-input {
      display: none;
    }

    .image-selector {
      text-align: center;
      height: 100%;
      display: flex;
      align-self: center;
      width: 100%;

      &.dragging {
        background-color: $primary;
        color: $white;
      }

      > div {
        width: 100%;
        align-self: center;
        text-align: center;
      }

      .message {
        font-size: 14px;
        margin-top: 1rem;
        padding: 0 1rem;
      }
    }
  }
}
