
          @import "@/styles/variables.scss";
        
@charset "UTF-8";
@import './fonts/FontawesomePro/css/all.css';
@import './styles/project.scss';

@font-face {
  font-family: 'Kakao';
  font-style: normal;
  font-weight: 300;
  src: url(./fonts/Kakao/KakaoLight.eot);
  src: url(./fonts/Kakao/KakaoLight.eot?#iefix) format('embedded-opentype'), url(./fonts/Kakao/KakaoLight.ttf) format('ttf'), url(./fonts/Kakao/KakaoLight.woff) format('woff'),
    url(./fonts/Kakao/KakaoOTFLight.otf) format('opentype'), url(./fonts/Kakao/KakaoLight.woff2) format('woff2');
}
@font-face {
  font-family: 'Kakao';
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/Kakao/KakaoRegular.eot);
  src: url(./fonts/Kakao/KakaoRegular.eot?#iefix) format('embedded-opentype'), url(./fonts/Kakao/KakaoRegular.ttf) format('ttf'), url(./fonts/Kakao/KakaoRegular.woff) format('woff'),
    url(./fonts/Kakao/KakaoOTFRegular.otf) format('opentype'), url(./fonts/Kakao/KakaoRegular.woff2) format('woff2');
}
@font-face {
  font-family: 'Kakao';
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/Kakao/KakaoBold.eot);
  src: url(./fonts/Kakao/KakaoBold.eot?#iefix) format('embedded-opentype'), url(./fonts/Kakao/KakaoBold.ttf) format('ttf'), url(./fonts/Kakao/KakaoBold.woff) format('woff'),
    url(./fonts/Kakao/KakaoOTFBold.otf) format('opentype'), url(./fonts/Kakao/KakaoBold.woff2) format('woff2');
}

@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 300;
  src: local('Noto Sans Light'), local('NotoSans-Light');
  src: url(./fonts/NotoSans/NotoSans-Light.eot);
  src: url(./fonts/NotoSans/NotoSans-Light.eot?#iefix) format('embedded-opentype'), url(./fonts/NotoSans/NotoSans-Light.woff) format('woff'),
    url(./fonts/NotoSans/NotoSans-Light.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans Regular'), local('NotoSans-Regular');
  src: url(./fonts/NotoSans/NotoSans-Regular.eot);
  src: url(./fonts/NotoSans/NotoSans-Regular.eot?#iefix) format('embedded-opentype'), url(./fonts/NotoSans/NotoSans-Regular.woff) format('woff'),
    url(./fonts/NotoSans/NotoSans-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 500;
  src: local('Noto Sans Medium'), local('NotoSans-Medium');
  src: url(./fonts/NotoSans/NotoSans-Medium.eot);
  src: url(./fonts/NotoSans/NotoSans-Medium.eot?#iefix) format('embedded-opentype'), url(./fonts/NotoSans/NotoSans-Medium.woff) format('woff'),
    url(./fonts/NotoSans/NotoSans-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'NotoSans';
  font-style: normal;
  font-weight: 700;
  src: local('Noto Sans Bold'), local('NotoSans-Bold');
  src: url(./fonts/NotoSans/NotoSans-Bold.eot);
  src: url(./fonts/NotoSans/NotoSans-Bold.eot?#iefix) format('embedded-opentype'), url(./fonts/NotoSans/NotoSans-Bold.woff) format('woff'), url(./fonts/NotoSans/NotoSans-Bold.otf) format('opentype');
}

body {
  margin: 0;
  color: $black;
  font-family: 'Kakao', 'NotoSans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}
