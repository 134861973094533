
          @import "@/styles/variables.scss";
        
.block-wrapper {

  position: relative;

  .block-row-wrapper {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .bottom-liner {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 0;
    left: 1rem;
    right: 1rem;
  }
}
