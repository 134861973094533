
          @import "@/styles/variables.scss";
        
.batch-history-list-wrapper {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  > div {
    ul {
      align-self: center;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        box-sizing: border-box;
        padding-bottom: 1rem;
        padding-top: 1rem;
        display: flex;
        border-bottom: 1px solid $gray-200;

        > div {
          align-self: center;
        }

        .info {
          .status {
            font-size: 18px;
            margin-bottom: 0.125rem;
            padding: 0 0.125rem;
            &.DONE {
              color: $green;
            }

            &.STARTED {
              color: $warning;
            }

            &.ERROR {
              color: $danger;
            }

            &.TERMINATED {
              color: $warning;
            }
          }

          .duration {
            font-size: 13px;

            > span {
              display: inline-block;
              padding: 2px;
              line-height: 100%;

              &.start-time {
              }

              &.wave {
                margin: 0 0.5rem;
              }

              &.end-time {
              }
            }
          }

          flex-grow: 1;
        }

        .count {
          min-width: 60px;
          text-align: center;
          > div {
            background-color: $gray-100;
            width: 40px;
            height: 40px;
            display: inline-flex;
            justify-content: center;
            border-radius: 5px;
            > span {
              align-self: center;
            }
          }
        }
      }
    }
  }
}
