
          @import "@/styles/variables.scss";
        
.scroll-calendar-wrapper {
  height: 55px;
  box-sizing: border-box;
  border-bottom: 1px solid #e3e3e3;
  margin: 0 auto;


  > div {
    max-width: $max-width;
    margin: 0 auto;
    position: relative;
  }

  .calendars {
    font-size: 14px;
    height: 55px;
    display: flex;
    padding: 0 10px;
    > div {
      align-self: center;
      height: 100%;

      &.month {
        display: flex;
        padding: 0 8px 10px;
        width: 50px;
        box-sizing: border-box;
        > div {
          align-self: center;
          .icon {
            margin-left: 4px;
          }
        }
      }

      &.days {
        flex-grow: 1;
        position: relative;
        > div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;

          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            white-space: nowrap;
            width: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            scrollbar-width: thin;
            &::-webkit-scrollbar {
              height: 8px;
            }
            &::-webkit-scrollbar-track {
              background-color: #f8f8f8;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #d4d4d4;
              border-radius: 4px;
            }

            > li {
              display: inline-block;
              text-align: center;
              width: 40px;

              @media (max-width: 576px) {
                width: calc(100% / 7);
              }

              -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
              cursor: pointer;

              &:hover {
                > div {
                  background-color: #f8f8f8;
                  transition: all 0.3s;
                }
              }

              &.selected {
                > div {
                  background-color: $color-point;
                  transition: all 0.3s;
                }
              }

              > div {
                width: 36px;
                display: inline-block;
                border-radius: 20px;

                &.holiday {
                  color: $red-color !important;
                }
                &.day-6 {
                  color: $blue;
                }
                .days-of-week {
                  height: 15px;
                  font-size: 12px;
                  justify-content: center;
                  display: flex;
                  margin-top: 5px;
                  > span {
                    align-self: center;
                  }
                }

                .days-of-month {
                  height: 25px;
                  display: flex;
                  justify-content: center;
                  > span {
                    align-self: center;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
