
          @import "@/styles/variables.scss";
        
.start-position-wrapper {
  height: 100%;
  font-size: 14px;
  padding: 0;
  display: flex;

  > div {
    width: 100%;
    align-self: center;
    max-width: $max-width;
    margin: 0 auto;
    padding: 2px 1rem 0;

    > span {
      cursor: pointer;
    }

    .icon {
      margin-right: 0.25rem;
    }

    .address {
      position: relative;
      top: -2px;

      > span {
        margin-right: 0.25rem;
      }
    }
  }
}
