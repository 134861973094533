
          @import "@/styles/variables.scss";
        
.regional-summary-list-wrapper {
  height: 140px;
  position: relative;
  width: 100%;

  > div {
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    ul {
      align-self: center;
      list-style: none;
      padding: 0 0 6px;
      margin: 0;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      box-sizing: border-box;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background-color: #f8f8f8;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #d4d4d4;
        border-radius: 4px;
      }

      li {
        display: inline-block;
        font-size: 13px;
        height: 126px;
        width: 120px;
        box-sizing: border-box;
        border: 1px solid #d1d1d1;
        margin-right: 12px;
        padding: 16px 8px;
        position: relative;

        .count {
          margin-bottom: 8px;
          text-align: center;
          color: $blue;
          height: 20px;

          .number {
            font-size: 17px;
          }

          .text {
            font-size: 13px;
          }

          .wave {
            font-size: 12px;
            margin: 0 4px;
          }
        }

        .name {
          font-size: 12px;
          text-align: center;
        }

        .map {
          width: 32px;
          height: 32px;
          position: absolute;
          bottom: 22px;
          right: 8px;
          box-sizing: border-box;
          line-height: 100%;

          .current {
            font-size: 24px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            box-sizing: border-box;
            > div {
              align-self: flex-end;
              line-height: 100%;
            }
          }

          .rect {
            width: 100%;
            height: 100%;
            border: 1px solid #ccc;
            border-radius: 4px;
            line-height: 100%;
            box-sizing: border-box;

            .jeju {
              width: 8px;
              height: 8px;
              border: 1px solid #ccc;
              border-radius: 50%;
              position: absolute;
              bottom: -16px;
              right: -1px;
            }

            .position {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: $color-sub1;
              position: absolute;

              &.region-s {
                top: 4px;
                left: 4px;
              }

              &.region-g {
                top: 4px;
                left: auto;
                right: 4px;
              }

              &.region-c {
                top: 11px;
                left: 4px;
              }

              &.region-k {
                top: 14px;
                left: auto;
                right: 4px;
              }

              &.region-j {
                top: 16px;
                left: 4px;
              }

              &.region-jj {
                top: auto;
                left: auto;
                right: -1px;
                bottom: -16px;
              }
            }
          }
        }
      }
    }
  }
}
