
          @import "@/styles/variables.scss";
        
.edit-customer-service-wrapper {
  form {
    @media (max-width: 768px) {
      padding-bottom: 0;
      padding-top: 0;
    }
  }
  .tel {
    display: flex;

    input {
      text-align: center;
      width: 100px;
    }
  }
}
