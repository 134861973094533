
          @import "@/styles/variables.scss";
        
.img-wrapper {
  > .icon {
    display: none;
  }
  &.no-image {
    > img {
      display: none;
    }

    > .icon {
      display: flex;
      justify-content: center;
      height: 100%;
      line-height: 100%;
      > span {
        line-height: 100%;
        display: inline-flex;
        width: 100%;
        height: 100%;
        background-color: $gray-100;
        align-self: center;
        font-size: 20px;
        border-radius: 50%;
        justify-content: center;
        > span {
          line-height: 100%;
          align-self: center;
        }
      }
    }
  }
}
