
          @import "@/styles/variables.scss";
        
.page-content-wrapper {
  flex-grow: 1;
  position: relative;
  width: 100%;
  flex-direction: column;
  padding: 0;
  max-width: $max-width;
  margin: 0 auto;

  &.has-padding {
    padding: 2rem 1rem;

    @media (max-width: 768px) {
      padding: 1rem;
    }
  }

  &.has-border {
    border-radius: 4px;
    background-color: $white;
    border: 1px solid rgba(0, 0, 0, 0.2);

    @media (max-width: 576px) {
      border: none;
    }
  }
}
