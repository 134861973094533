
          @import "@/styles/variables.scss";
        
.logo-wrapper {
  text-align: center;

  img {
    width: 80px;
    height: 80px;
  }

  &.size-sm {
    > img {
      width: 60px;
      height: 60px;
    }
  }
}
