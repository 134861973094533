
          @import "@/styles/variables.scss";
        
.edit-board-wrapper {
  .block {
    margin-bottom: 1rem;
  }

  form {
    padding: 0;
  }
}
