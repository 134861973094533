
          @import "@/styles/variables.scss";
        
.club-summary-list-wrapper {
  height: 140px;
  position: relative;
  box-sizing: border-box;

  > div {
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    .message {
      align-self: center;
      justify-content: center;
      width: 100%;
      text-align: center;

      .icon {
        font-size: 28px;
        margin-bottom: 8px;
      }
    }

    ul {
      align-self: center;
      list-style: none;
      padding: 0 0 6px;
      margin: 0;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      box-sizing: border-box;
      scroll-behavior: smooth;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background-color: #f8f8f8;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #d4d4d4;
        border-radius: 4px;
      }

      li {
        vertical-align: top;
        font-size: 13px;
        height: 120px;
        min-width: 280px;
        box-sizing: border-box;
        border: 1px solid #d1d1d1;
        margin-right: 10px;
        border-radius: 5px;
        padding: 16px;
        display: inline-block;
        background-color: $white;
        position: relative;
        @include shadow;

        .day-weather {
          flex-grow: 1;
          margin-left: 8px;
        }

        .weather-icon {
          position: absolute;
          bottom: 16px;
          left: 40px;
          z-index: 1;

          > span {
            width: 28px;
            height: 28px;
            display: flex;
            border: 1px solid $border-color;
            background-color: $white;
            justify-content: center;
            border-radius: 50%;
            > i {
              align-self: center;
            }

            &.sunny {
              font-size: 18px;
              color: $yellow;
            }

            &.cloudy {
              color: $gray-600;
              font-size: 14px;
            }

            &.rainy {
              color: $warning;
              font-size: 16px;
            }
          }
        }

        &:first-child {
          margin-left: 1rem;
        }

        &:last-child {
          @media (max-width: 768px) {
            margin-right: 2rem;
          }
        }

        .club-info {
          flex-grow: 1;
          position: relative;
          font-size: 20px;
          margin-bottom: 8px;
          display: flex;
          color: $link-color;

          .club-name,
          .tee-count,
          .home-link {
            cursor: pointer;
          }
        }

        .image-and-other {
          display: flex;

          .club-image {
            flex-grow: 0;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }

          .other {
            flex-grow: 1;
            margin-left: 0.5rem;
            .time-and-price {
              display: flex;
              margin-bottom: 4px;

              .icon {
                margin-right: 0.25rem;
              }
            }

            .position-info {
              display: flex;
              margin-bottom: 4px;
            }

            .weather-and-permission-date {
              _color: $gray-600;

              display: flex;
              > span {
                align-self: center;

                .label {
                  font-size: 10px;
                  margin-right: 0.25rem;
                }

                .slash {
                  margin: 0 0.25rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
