
          @import "@/styles/variables.scss";
        
.user-grade-wrapper {
  position: relative;
  padding: 0.5rem;
  display: flex;
  font-size: 13px;
  border-radius: 5px;
  @include shadow;
  max-width: $max-width;
  margin: 1rem auto;

  @media (max-width: 1236px) {
    margin: 1rem;
  }

  &.skip {
    display: none;
  }

  &.null {
    border: 1px solid lighten($danger, 20%);
    background-color: lighten($danger, 40%);
  }

  &.SILVER {
    border: 1px solid lighten($yellow, 20%);
    background-color: lighten($yellow, 40%);
  }

  > div {
    align-self: center;

    &.message {
      flex-grow: 1;
      line-height: 160%;
      padding-left: 0.25rem;

      a {
        color: $blue;
        &:visited {
          color: $blue;
        }
      }
    }

    &.grade-button {
      padding: 0 0.25rem;
    }
  }
}
