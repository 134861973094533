
          @import "@/styles/variables.scss";
        
.golf-ball-wrapper {
  display: inline-block;
  background-color: $white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 2px solid #333;
  position: relative;
  animation: rotate-animation 1s infinite linear;
  box-shadow: 0 0 10px 4px rgba(0,0,0,0.1);

  .dot {
    width: 5px;
    height: 5px;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    border-radius: 50%;

    &.dot-1 {
      right: 6px;
      top: 6px;
    }

    &.dot-2 {
      right: 3px;
      top: 15px;
    }

    &.dot-3 {
      right: 14px;
      top: 3px;
    }
  }
}
