
          @import "@/styles/variables.scss";
        
.batch-histories-wrapper {
  .page-title {
    .text {
      display: flex;

      > div {
        align-self: center;
      }

      .batch-job-name {
      }

      .title-label {
        font-size: 12px;
        font-weight: normal;
        margin-left: 0.5rem;
      }
    }
  }

  .tab {
    height: 30px;
    width: calc(100% - 2rem);
  }
}
