
          @import "@/styles/variables.scss";
        
.regional-summary-bar-list-wrapper {
  position: relative;

  ul {
    align-self: center;
    list-style: none;
    padding: 0 0 24px;
    margin: 0;
    vertical-align: top;
    box-sizing: border-box;

    li {
      vertical-align: top;
      display: inline-block;
      width: calc((100% - 60px) / 6);
      height: 68px;
      margin-right: 10px;
      margin-bottom: 10px;
      box-sizing: border-box;
      border: 1px solid #d1d1d1;
      padding: 1rem;
      position: relative;
      border-radius: 5px;
      background-color: $white;
      @include shadow;
      &:nth-child(6n) {
        margin-right: 0;
      }

      @media (max-width: 992px) {
        width: calc((100% - 20px) / 3);
        &:nth-child(6n) {
          margin-right: 10px;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      @media (max-width: 768px) {
        width: calc((100% - 10px) / 2);

        &:nth-child(3n) {
          margin-right: 10px;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }
      }

      > div {
        display: flex;
        > div {
          align-self: center;

          &.count {
            flex-grow: 1;
            text-align: right;
            cursor: pointer;

            .tee-number,
            .tee-text {
              color: $link-color;
              font-size: 17px;
            }

            .club-info {
              font-size: 11px;
            }
          }
        }
      }

      .name {
        font-size: 14px;
        text-align: center;
      }
    }
  }
}
