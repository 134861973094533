
          @import "@/styles/variables.scss";
        
.boards-wrapper {
  div.content {
    ul {
      align-self: center;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        box-sizing: border-box;
        display: flex;
        border-bottom: 1px solid $gray-200;

        &:first-child {
          border-top: 1px solid $gray-200;
        }

        > div {
          padding: 1rem 0.5rem;
        }

        .subject {
          flex-grow: 1;
          overflow-x: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .info {
          min-width: 90px;
          text-align: right;
          > span {
            white-space: nowrap;
            font-size: 12px;
            display: inline-block;
            line-height: 100%;
            padding: 2px 4px;
            background-color: $gray-200;
            border-radius: 5px;
            margin-right: 4px;

            &:last-child {
              margin-right: 0;
            }

            &.notice-yn {
              background-color: $yellow;
            }
          }
        }
      }
    }
  }
}
