
          @import "@/styles/variables.scss";
        
.talks-wrapper {
  height: calc(100vh - 90px - 55px);
  display: flex;

  .page-title {
    .desc {
      font-size: 13px;
      font-weight: normal;
      @media (max-width: 768px) {
        font-size: 12px;
        letter-spacing: -1px;
      }
    }
    @media (max-width: 768px) {
      border-bottom: 1px solid $border-color !important;
    }

    .current-date {
      font-size: 14px;
      z-index: 1;
      display: inline-flex;
      display: none;

      > div {
        align-self: center;

        &.date {
          margin: 0 0.5rem;
        }

        button {
          padding: 0;
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  @media (min-width: 768px) {
    height: calc(100vh - 90px - 55px) !important;
  }

  @media (max-width: 768px) {
    padding-bottom: 0;
  }

  .content {
    display: flex;
    position: relative;

    .empty-content {
      text-align: center;
    }

    .talk-list {
      flex-grow: 1;
      padding: 0.5rem;
      @media (max-width: 768px) {
        padding: 0 0.5rem;
      }
      > div {
        width: 100%;
        height: 100%;
        position: relative;

        > div {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow-y: auto;
          padding-right: 0.5rem;
          padding-left: 0.5rem;

          ul {
            list-style: none;
            padding: 1rem 0;
            margin: 0;
            > li {
              margin-bottom: 1rem;

              &.my {
                text-align: right;
              }
              > div {
                border-radius: 4px;
                display: inline-block;
                text-align: left;
                border: 1px solid rgba(0, 0, 0, 0.1);
                box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.02);
                padding: 0.75rem 0.75rem;
                min-width: 140px;
                background-color: $white;
                .nickname {
                  font-size: 12px;
                  text-transform: uppercase;
                  margin-bottom: 0.25rem;
                  color: $gray-600;
                }
                .message {
                  white-space: pre-wrap;
                  font-size: 14px;
                  margin-bottom: 0.25rem;
                }

                .time {
                  font-size: 12px;
                  text-align: right;
                  color: $gray-600;
                }
              }

              &.my {
                > div {
                  background-color: $yellow;
                  border: none;
                }
              }
            }
          }
        }
      }
    }

    .talk-message {
      display: flex;
      height: 60px;
      padding: 0;

      @media (max-width: 768px) {
        height: 60px;
        padding: 0.75rem 0.5rem;
        background-color: $gray-100;
        border-top: 1px solid $border-color;
      }

      > div {
        height: 100%;
      }
      .message {
        flex-grow: 1;

        .text-area {
          height: 100%;
          @media (max-width: 768px) {
            border-radius: 10px;
          }
        }
      }

      .button {
        margin-left: 1rem;
        align-self: center;
        display: flex;
        @media (max-width: 768px) {
          margin-left: 0.5rem;
        }
        > button {
          align-self: center;
          width: 40px;
          height: 40px;
          background-color: $yellow;
          line-height: 0;
          font-size: 24px;
          color: $black;
          padding: 0;
          border-radius: 50%;

          @media (max-width: 768px) {
            width: 32px;
            height: 32px;
            font-size: 18px;
          }
        }
      }
    }
  }
}
