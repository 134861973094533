
          @import "@/styles/variables.scss";
        
.main-wrapper {
  padding: 85px 0 0;
  position: relative;
  left: 0;
  &.hide {
    position: relative;
    left: -100vw;
    transition: left 0.3s;
    transition-timing-function: ease-in-out;
  }

  .scroll-calendar {
    &.hide {
      left: -100vw;
      transition: left 0.3s;
      transition-timing-function: ease-in-out;
    }
  }

  .start-position {
    position: fixed;
    width: 100%;
    top: 55px;
    left: 0;
    right: 0;
    height: 30px;
    box-sizing: border-box;
    z-index: 900;
    background-color: white;
    transition: top 0.3s;

    &.move-up {
      top: 0;
      transition: top 0.3s;
    }
  }

  .scroll-calendar {
    position: fixed;
    top: 85px;
    left: 0;
    right: 0;
    height: 55px;
    transition: top 0.3s;
    z-index: 900;
    background-color: $white;

    &.move-up {
      top: 30px;
      transition: top 0.3s;
    }
  }

  .max-content {
    max-width: $max-width;
    margin: 0 auto;
  }

  .club-by-tag {
    padding: 2rem 1rem 0;
    position: relative;
    overflow: hidden;

    .circle-1 {
      width: 300px;
      height: 300px;
      top: -100px;
      left: -100px;
      background-color: $color-point;
      border-radius: 50%;
      position: absolute;
      opacity: 0.2;
      animation: bouncing 5s infinite ease-in-out;
    }

    .circle-2 {
      width: 400px;
      height: 400px;
      top: -200px;
      right: -100px;
      background-color: $color-sub2;
      border-radius: 50%;
      position: absolute;
      opacity: 0.1;
      animation: bouncing 5s infinite ease-in-out;
    }

    .rect {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $color-sub4;
      position: absolute;
      opacity: 0.1;
    }

    .title {
      position: relative;
    }

    .tag-selector {
      margin-top: 8px;
    }

    .club-summary-list {
      @media (max-width: 768px) {
        margin-bottom: 1rem;
        margin-left: -1rem;
        margin-right: -2rem;
      }
    }
  }

  .club-by-region {
    padding: 2rem 1rem 0;
    background-color: $gray-100;
  }

  .club-by-sort {
    padding: 2rem 1rem;
    background-color: lighten($color-sub2, 32%);
  }

  .regional-summary-bar {
  }

  .sort-order {
    display: flex;

    > div {
      align-self: center;
    }

    .order {
      margin-right: 0.5rem;
    }

    .values {
      flex-grow: 1;

      ul {
        padding-bottom: 0;
      }
    }
  }
}
