
          @import "@/styles/variables.scss";
        
.empty-content-wrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  > div {
    align-self: center;

    .icon {
      font-size: 30px;
      text-align: center;
      margin-bottom: 1rem;
    }
  }
}
