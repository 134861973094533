
          @import "@/styles/variables.scss";
        
.config-wrapper {
  ul {
    padding: 1rem;
    margin: 0;
    list-style: none;

    > li {
      padding: 1rem;
      display: flex;

      > a {
        text-decoration: none;
        color: $blue;

        &:visited {
          color: $blue;
        }
        > div {
          display: flex;
          align-self: center;
          .icon {
            margin-right: 0.5rem;
          }
          .text {
            flex-grow: 1;
          }
        }
      }
    }
  }
}
