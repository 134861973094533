
          @import "@/styles/variables.scss";
        
.app-wrapper {
  margin: 0 auto;

  @media (max-width: 768px) {
    padding-bottom: 70px;
  }

  &.talks {
    padding-bottom: 0;
  }

  .main-content {
    margin: 0 auto;
    padding-top: 55px;
    @media (max-width: 768px) {
      padding-top: 0;
    }
  }

  &.is-main {
    .main-content {
      @media (max-width: 768px) {
        padding-top: 55px;
      }
    }
  }

  .navigator {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $white;
    height: 70px;
    border-top: 1px solid $border-color;
    z-index: 2;
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .talks-footer {
    @media (max-width: 768px) {
      display: none;
    }
  }
}
