
          @import "@/styles/variables.scss";
        
.mobile-menu-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 992;
  left: 100%;
  opacity: 0;

  .logo {
    height: 100%;
    min-width: 120px;
    position: relative;
    padding-top: 0;
    display: inline-flex;

    > a {
      align-self: center;
      line-height: 0;
      display: flex;
      color: $black;
      text-decoration: none;
      img {
        width: 40px;
        height: 40px;
        margin-left: 4px;
      }
      .gongtee {
        font-size: 16px;
        line-height: 100%;
        z-index: 2;
        text-align: left;
        display: inline-flex;

        > div {
          align-self: center;
          > div.name {
            font-weight: bold;
          }

          > div.slogan {
            font-size: 13px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  &.open {
    left: 0;
    opacity: 1;
  }

  > div {
    background-color: $white;
    position: absolute;
    top: 0;
    right: -100%;
    bottom: 0;
    width: 80%;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);

    &.open {
      right: 0;
      transition: right 0.3s;
    }

    .menu-top-button {
      display: flex;
      text-align: right;
      height: 55px;
      padding-right: 0.5rem;
      .logo {
        flex-grow: 1;
      }
      button {
        align-self: center;
        display: inline-flex;
        padding-left: 10px;
        padding-right: 10px;
        border: none;
        font-size: 20px;

        > i {
          align-self: center;
        }
      }
    }

    .menu-list {
      padding: 1rem;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          &.admin-label {
            margin-top: 1rem;
            > span {
              font-size: 12px;
              display: inline-block;
              color: $gray-600;
            }
          }

          &.logout {
            text-align: right;
            margin-top: 1rem;
            > span {
              color: $link-color;
              padding: 0.5rem;
              text-decoration: none;
              font-size: 14px;
              display: inline-block;
            }
          }
          > a {
            display: flex;
            width: 100%;
            color: $black;
            text-decoration: none;
            padding: 0.75rem;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);

            &:visited {
              color: $black;
            }
            > span {
              align-self: center;
            }

            .icon {
              margin-right: 0.5rem;
            }

            .text {
              flex-grow: 1;
            }
          }
        }
      }
    }

    .bottom-menu {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0.5rem;

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: right;
        font-size: 14px;

        li {
          display: inline-block;
          > a {
            color: $link-color;
            text-decoration: none;
            display: inline-block;
            padding: 0.5rem;

            &:visited {
              color: $link-color;
            }
          }
        }
      }
    }
  }
}
