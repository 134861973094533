
          @import "@/styles/variables.scss";
        
.board-wrapper {
  .page-title {
    width: 100%;

    .links {
      white-space: nowrap;
      overflow: visible;
      margin-left: 1rem;
    }
  }
}
