
          @import "@/styles/variables.scss";
        
.my-wrapper {
  .cs-info {
    padding: 0 0 1rem;

    @media (max-width: 768px) {
      padding: 0.5rem 0 1rem;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      @media (max-width: 768px) {
        text-align: center;
      }

      li {
        display: inline-block;
        padding: 0.75rem 2rem 0.5rem;
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: 5px;
        margin-right: 1rem;
        width: 120px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        .icon {
          font-size: 22px;
          text-align: center;

          > span {
            display: inline-flex;
            width: 40px;
            height: 40px;
            background-color: #333;
            color: $white;
            border-radius: 50%;
            justify-content: center;
            line-height: 0;

            i {
              align-self: center;
              line-height: 0;
            }
          }
        }

        &.enabled:hover {
          .icon {
            > span {
              background-color: $yellow;
              color: $black;
            }
          }
        }

        &.disabled {
          opacity: 0.4;
          cursor: text;
        }

        .line {
          > div {
            height: 1px;
            background-color: $gray-400;
            margin: 0.5rem -1rem;
          }
        }

        .text {
          text-align: center;
          font-size: 14px;
          position: relative;
        }
      }
    }
  }

  .my-info-form {
    padding: 0;
    @media (max-width: 768px) {
      padding: 0;
    }
  }

  .register-content {
    @media (max-width: 768px) {
      padding: 1rem 1rem 0;
      .label {
        height: auto;
      }

      .text-data {
        height: auto;
        min-height: auto;
        padding: 0.5rem 0.5rem;
      }
    }

    .vendor {
      display: inline-block;
      margin-right: 0.5rem;

      &.kakao {
        background-color: $kakao-color;
        display: inline-block;
        text-transform: uppercase;
        font-size: 12px;
        padding: 3px;
        border-radius: 3px;
        font-weight: bold;
      }

      &.naver {
        background-color: $naver-color;
        color: $white;
        display: inline-block;
        text-transform: uppercase;
        font-size: 12px;
        padding: 3px;
        border-radius: 3px;
        font-weight: bold;
      }
    }

    .no-email {
      margin-bottom: 0;
      min-height: auto;
      padding-bottom: 0;
    }

    .desc {
      color: $gray-600;
      font-size: 14px;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      > a {
        text-decoration: none;
      }
    }

    a.leave {
      color: $danger !important;
      &:active {
        color: $danger !important;
      }
    }
  }

  .copy {
    margin-left: 0.5rem;
  }
}
