
          @import "@/styles/variables.scss";
        
.user-club-setting-wrapper {
  .message {
    margin-bottom: 1rem;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      display: inline-block;
      width: calc((100% - 40px) / 5);
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 1rem 0.5rem;

      border-radius: 5px;
      border: 1px solid $border-color;
      cursor: pointer;

      &.excluded {
        text-decoration: line-through;
        background-color: $gray-300;
      }

      > div {
        display: flex;
        .checked {
          margin-right: 0.5rem;
        }

        .name {
          flex-grow: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &:nth-child(5n) {
        margin-right: 0;
      }

      @media (max-width: 992px) {
        width: calc((100% - 30px) / 4);

        &:nth-child(5n) {
          margin-right: 10px;
        }

        &:nth-child(4n) {
          margin-right: 0;
        }
      }

      @media (max-width: 768px) {
        width: calc((100% - 20px) / 3);

        &:nth-child(4n) {
          margin-right: 10px;
        }

        &:nth-child(3n) {
          margin-right: 0;
        }
      }

      @media (max-width: 576px) {
        width: calc((100% - 10px) / 2);

        &:nth-child(3n) {
          margin-right: 10px;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }
      }
    }
  }
}
