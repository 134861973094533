
          @import "@/styles/variables.scss";
        
.request-progress-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
  display: flex;
  justify-content: center;
  padding: 1rem;

  > div {
    align-self: center;
    background-color: $white;
    width: 100%;
    max-width: 400px;
    padding: 1rem;
    border-radius: 10px;

    .title {
      text-align: center;
      width: 100%;
      margin-bottom: 1rem;
    }

    .info {
      > div.ball {
        text-align: center;
      }

      .progress {
        .count {
          text-align: center;
          margin-bottom: 0.5rem;
          display: flex;
          flex-direction: row;

          .number {
            font-size: 24px;
            flex-grow: 1;

            &.start {
              text-align: right;
            }

            &.end {
              text-align: left;
            }
          }

          .slash {
            margin: 0 0.5rem;
            align-self: center;
          }
        }

        .bar {
          height: 30px;
          position: relative;
          background-color: $gray-200;
          border-radius: 2px;

          > div.item {
            height: 100%;
            background-color: $yellow;
            transition: all 0.3s;
            border-radius: 2px;
          }

          > div.club {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            justify-content: center;

            > div {
              align-self: center;
            }
          }
        }
      }

      .club {
        text-align: center;
      }
    }
  }
}
