
          @import "@/styles/variables.scss";
        
.ad-wrapper {
  display: flex;
  height: 100px;
  position: relative;
  margin-left: -16px;
  margin-right: -16px;
  background-color: #f9f9f9;
  background-color: $blue;
  color: white;
  > div {
    align-self: center;
    width: 100%;
    text-align: center;
    font-family: NotoSans, sans-serif;
    display: flex;

    > div {
      &.image {
        position: absolute;
        top: 30px;
        left: 10px;

        img {
          width: 40px;
          height: 40px;
        }
      }

      &.ad {
        position: absolute;
        top: 6px;
        right: 6px;
        font-size: 10px;
        color: $black;
        background-color: white;
        display: inline-block;
        padding: 2px 6px;
        border-radius: 2px;
        font-weight: bold;
      }

      &.text {
        flex-grow: 1;
        display: flex;
        justify-content: center;

        > div {
          align-self: center;
          > .line1 {
            font-size: 12px;
            margin-bottom: 4px;
          }

          > .line2 {
            font-size: 14px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
