
          @import "@/styles/variables.scss";
        
.message-wrapper {
  p {
    text-align: center;
  }

  .content {
    min-height: 300px;
    display: flex;
    justify-content: center;

    .icon {
      text-align: center;
      font-size: 40px;

      > span {
        display: inline-flex;
        width: 80px;
        height: 80px;
        background-color: $danger;
        color: $white;
        justify-content: center;
        border-radius: 50%;
        > span {
          align-self: center;
          line-height: 0;
        }
      }
    }

    p {
      text-align: center;
    }
  }
}
