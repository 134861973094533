
          @import "@/styles/variables.scss";
        
.notice-popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.3);

  > div {
    width: 100%;
    height: 100%;
    padding: 1rem;
    > div {
      width: 100%;
      height: 100%;
      background-color: $white;
      max-width: 768px;
      margin: 0 auto;
      border-radius: 2px;
      display: flex;
      flex-direction: column;

      .notice-header {
        padding: 1rem;
        position: relative;
        background-color: $gray-100;

        .count-index {
          text-align: center;
          font-size: 14px;
          margin-bottom: 0.5rem;

          > span {
            margin: 0 0.25rem;
          }
        }

        .subject {
          text-align: center;
          font-weight: bold;
        }

        .exit-button {
          position: absolute;
          top: 16px;
          right: 13px;
        }
      }

      .creation-date {
        font-size: 12px;
        margin-right: 1rem;
        margin-top: 0.5rem;
        text-align: center;
      }

      .content {
        flex-grow: 1;
        padding: 1rem;
        white-space: pre-wrap;
        overflow-y: auto;

        img {
          width: 100%;
          max-width: 400px;
          margin: 0 1rem;
          border: 1px solid $border-color;

          @media (max-width: 768px) {
            max-width: 100%;
            margin: 0;
          }
        }

        em {
          font-style: normal;
          font-weight: bold;
          color: $danger;
        }

        scrollbar-width: thin;
        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-track {
          background-color: #f8f8f8;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #d4d4d4;
          border-radius: 4px;
        }
      }

      .buttons {
        display: flex;
        font-size: 14px;
        background-color: $gray-100;

        > div {
          align-self: center;
          cursor: pointer;
        }

        .ignore-all {
          flex-grow: 1;
          > span {
            padding: 1rem;
          }
        }

        .move {
          margin: 0 0.5rem;
          height: 100%;
          padding: 1rem;
        }
      }
    }
  }
}
