
          @import "@/styles/variables.scss";
        
.footer-wrapper {

  padding: 1rem;
  height: 90px;
  border-top: 1px solid $border-color;
  background-color: $gray-100;
  background: linear-gradient($gray-100 0%, rgba(255, 255, 255, 0) 100%);

  .max-content {
    max-width: $max-width;
    margin: 0 auto;
    display: flex;

    > div {
      align-self: center;
    }
  }

  .logo {
    flex-grow: 0;
    margin-right: 1rem;
    img {
      width: 40px;
      height: 40px;
    }
  }

  .version {
    text-transform: uppercase;
    font-size: 12px;

    .product {
      font-size: 14px;
      font-weight: bold;
    }

    .app,
    .api {
      font-size: 11px;
    }

    .others {
      margin-top: 0.25rem;
      font-size: 12px;
      > a {
        color: $link-color;
        text-decoration: none;
      }
    }
  }
}
