
          @import "@/styles/variables.scss";
        
.batch-history-wrapper {
  .message {
    line-height: 160%;
    width: 100%;
    overflow-x: auto;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: #f8f8f8;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d4d4d4;
      border-radius: 4px;
    }

    span {
      white-space: pre;
      font-size: 14px;
      overflow-x: auto;
    }
  }
}
