
          @import "@/styles/variables.scss";
        
.club-list-wrapper {
  @media (max-width: 768px) {
    padding-bottom: 0;
  }

  .tab {
    height: 30px;
    width: calc(100% - 2rem);
    margin: 1rem 1rem 0;

    @media (max-width: 768px) {
      margin-top: 0;
    }
  }
}
