
          @import "@/styles/variables.scss";
        
.system-wrapper {
  .system-info-box {
    .title {
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid $border-color;
      display: flex;

      > div {
        align-self: center;

        &:first-child {
          flex-grow: 1;
        }
      }
    }

    .content {
      padding: 0.5rem;

      > div {
        display: flex;
        margin-bottom: 0.5rem;

        &.no-data {
          text-align: center;
          display: block;
          padding: 1rem;
        }

        .description {
          font-size: 14px;
          color: $gray-600;
          margin-top: 0.25rem;
        }

        &.code {
          > div {
            align-self: flex-start;
          }
        }

        > div {
          align-self: center;

          &:first-child {
            min-width: 140px;
          }
        }

        &.management {
          margin-top: 1rem;

          > div {
            align-self: flex-start;
            > div {
              margin-bottom: 1rem;
            }
          }
        }
      }
    }
  }
}
