
          @import "@/styles/variables.scss";
        
.simple-club-list-wrapper {
  position: relative;
  width: 100%;
  box-sizing: border-box;

  > div {
    .message {
      align-self: center;
      justify-content: center;
      width: 100%;
      text-align: center;
      padding: 2rem 0;

      .icon {
        font-size: 28px;
        margin-bottom: 8px;
      }
    }

    ul {
      align-self: center;
      list-style: none;
      margin: 0;
      padding: 0;
      box-sizing: border-box;

      li {
        vertical-align: top;
        display: inline-flex;
        font-size: 13px;
        width: calc((100% - 20px) / 3);
        margin-right: 10px;
        box-sizing: border-box;
        border: 1px solid #d1d1d1;
        margin-bottom: 10px;
        border-radius: 5px;
        background-color: $white;
        @include shadow;

        &:nth-child(3n) {
          margin-right: 0;
        }

        @media (max-width: 1200px) {
          width: calc((100% - 10px) / 2);

          &:nth-child(3n) {
            margin-right: 10px;
          }

          &:nth-child(2n) {
            margin-right: 0;
          }
        }

        @media (max-width: 768px) {
          width: 100%;
        }

        &:last-child {
          margin-bottom: 0;
        }

        .club-info {
          padding: 12px;
          display: flex;
          flex-grow: 1;
          position: relative;

          .club-image {
            flex-grow: 0;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            margin-right: 8px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }

          .general-info {
            flex-grow: 1;

            .club-name {
              display: flex;
              margin-bottom: 0.5rem;

              .name {
                font-size: 18px;
                color: $link-color;
                letter-spacing: -0.5px;
                white-space: nowrap;
                cursor: pointer;
              }

              .cost {
                align-self: center;
                white-space: nowrap;
                letter-spacing: -0.5px;
                .wave {
                  margin: 0 0.125rem;
                }
              }
            }

            .position-info {
              display: flex;

              .position {
                min-width: 53px;
              }

              .tee-time {
                min-width: 60px;
              }
            }
          }

          .count-info {
            flex-grow: 0;
            min-width: 52px;
            margin-right: 6px;

            .count {
              flex-grow: 1;
              font-size: 18px;
              text-align: right;
              color: $link-color;
              cursor: pointer;
            }
          }

          > div {
            align-self: center;
          }
        }
      }
    }
  }
}
