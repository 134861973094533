
          @import "@/styles/variables.scss";
        
.login-wrapper {
  padding: 0;

  .logo {
    @media (max-width: 768px) {
      padding-top: 1rem;
    }
  }

  .form {
    padding: 0;
    max-width: 300px;
    margin: 0 auto;

    .title {
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  .login-content {
    margin: 0 auto;
    background-color: $white;
    position: relative;
    border-radius: 8px;

    .message {
      margin: 0;
    }

    .email {
      margin-top: 1rem;
    }

    input {
      border: none;
      border-bottom: 1px solid #666;
      border-radius: 0;
      padding: 12px;
      height: auto;
    }

    p {
      text-align: center;
      margin: 0 0 0.5rem;
    }
    > div {
      margin-bottom: 1rem;
    }

    .checked {
      text-align: center;
    }

    .login-button {
      text-align: center;

      > button {
        margin: 0 0.25rem;
        width: 70px;
      }
    }

    .links {
      text-align: center;
      margin-top: 1rem;

      > a {
        color: $link-color;
        text-decoration: none;
        &:visited {
          color: $link-color;
        }
      }
    }
  }

  .simple-login {
    padding: 1rem;
    width: 100%;
    margin-bottom: 1rem;
    _background-color: $light;

    > div {
      max-width: $max-width;
      margin: 0 auto;
    }
  }

  .line {
    margin-bottom: 0.5rem;
    > div {
      text-align: center;
      font-size: 12px;
      color: $gray-600;
    }
  }

  .warning-info {
    max-width: 420px;
    margin: 1rem auto;
  }

  .sns-buttons {
    margin-bottom: 0 !important;

    > div {
      text-align: center;

      button {
        padding: 0;
        font-family: NotoSans, sans-serif;
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        font-size: 14px;
        line-height: 100%;
        display: inline-flex;
        height: 40px;
        border-radius: 4px;

        &.facebook {
          background-color: $facebook-color;
          color: $white;
          display: none;
        }

        &.kakao {
          background-color: $kakao-color;
        }

        &.naver {
          background-color: $naver-color;
          color: $white;
        }

        &.google {
          background-color: $google-color;
          color: $white;
          display: none;
        }

        > div {
          display: flex;
          text-align: center;
          justify-content: center;

          .vendor {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            display: inline-flex;
            justify-content: center;

            img {
              width: 30px;
              height: 30px;
              border-radius: 2px;
              align-self: center;
            }
          }

          .text {
            align-self: center;
            padding-right: 1rem;
          }
        }
      }
    }
  }
}
