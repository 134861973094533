
          @import "@/styles/variables.scss";
        
.exit-button-wrapper {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  cursor: pointer;

  > div.x-1 {
    width: 140%;
    height: 140%;
    border-right: 1px solid $white;
    transform: rotate(45deg);
    position: absolute;
    top: -68%;
    left: -69%;
    transform-origin: 50% 50%;
  }

  > div.x-2 {
    width: 140%;
    height: 140%;
    border-right: 1px solid $white;
    transform: rotate(-45deg);
    position: absolute;
    top: 29%;
    left: -68%;
    transform-origin: 50% 50%;
  }

  &.color-white {
    &:hover {
      > div.x-1,
      > div.x-2 {
        border-color: $yellow !important;
      }
    }
  }
}
