
          @import "@/styles/variables.scss";
        
.customer-service-info-wrapper {
  .page-content {
    @media (max-width: 768px) {
      padding-top: 0;
    }

    .title {
      font-weight: bold;
      margin-top: 1rem;
      font-size: 20px;

      @media (max-width: 768px) {
        font-size: 18px;
        padding-top: 0;
        margin-top: 0;
      }
    }

    .other {
      font-size: 14px;
      margin-bottom: 1rem;
      margin-top: 1rem;
      display: flex;

      > div {
        align-self: center;
        &.icon {
          margin-right: 0.5rem;

          > span {
            display: inline-flex;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $gray-200;
            font-size: 24px;
            justify-content: center;
            i {
              align-self: center;
            }
          }
        }

        &.user-info {
          > div {
            > div {
              margin-bottom: 0.25rem;
              &:last-child {
                margin-bottom: 0;
              }

              .label {
                min-width: 50px;
                display: inline-block;
              }

              .phone,
              .email {
                margin-left: 0.25rem;
                text-transform: uppercase;
              }
            }
          }
        }
      }
    }

    .content {
      padding: 1rem;
      white-space: pre-wrap;
      border: 1px solid $border-color;
      margin-bottom: 1rem;
      border-radius: 5px;

      @media (max-width: 768px) {
        border: none;
        padding: 1rem;
        border-radius: 0;
        background-color: $gray-100;
      }

      > div.inquiry-info {
        padding-bottom: 1rem;
        display: flex;
        font-size: 14px;
        border-bottom: 1px solid $border-color;

        @media (max-width: 768px) {
          padding-bottom: 0;
          border-bottom: none;
        }

        > div {
          align-self: center;
        }

        .time {
          flex-grow: 1;
          text-align: right;
          margin-right: 0.5rem;
        }

        .inquiry-type {
          display: inline-block;
          padding: 4px 8px;
          border-radius: 2px;
          margin-right: 1rem;

          &.INQUIRY {
            background-color: $color-sub2;
            color: $white;
          }

          &.ANSWER {
            background-color: $color-sub4;
            color: $white;
          }
        }
      }

      > div.content-text {
        padding: 2rem 1rem 1rem;
        border-radius: 5px;

        @media (max-width: 768px) {
          padding: 0;
          margin-top: 1rem;
        }
      }

      &.reply {
        @media (max-width: 768px) {
          background-color: $white;
          padding: 0;
        }
        > div.content-text {
          padding: 1rem 0 0;
          border-radius: 5px;

          @media (max-width: 768px) {
            margin-top: 0;
          }

          .text-area {
            border: none !important;
            background-color: $gray-100;
            padding: 1rem;

            @media (max-width: 768px) {
              background-color: $white;
              border: 1px solid $border-color !important;
            }
          }
        }
      }

      .content-button {
        margin-top: 1rem;
        text-align: right;
      }
    }

    .separator {
      margin: 2rem 0;
      @media (max-width: 768px) {
        display: none;
      }
      > div {
        height: 1px;
        background-color: $border-color;
      }
    }
    .info {
      @media (max-width: 768px) {
        border: none;
      }
    }
  }
}
