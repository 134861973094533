
          @import "@/styles/variables.scss";
        
.info-wrapper {
  display: flex;
  border-radius: 4px;
  margin: 0 0.5rem 1rem;

  &.size-sm {
    font-size: 14px;
  }

  > div {
    align-self: center;

    &.info-icon {
      flex-grow: 0;
      padding: 1rem 0 1rem 1rem;
      @media (max-width: 768px) {
        padding: 0.5rem 0 0.5rem 0.5rem;
      }
    }

    &.info-text {
      flex-grow: 1;
      padding: 1rem;
      @media (max-width: 768px) {
        padding: 0.5rem;
      }
    }
  }

  &.color-white {
    background-color: $white;
  }

  &.color-gray {
    background-color: rgba(0, 0, 0, 0.4);
  }

  &.color-black {
    background-color: $black;
    color: $white;
  }

  &.color-light {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &.color-green {
    background-color: $green;
    color: $white;
  }

  &.color-danger {
    background-color: $danger;
    color: $white;
  }

  &.outline {
    background-color: $white;
    color: $black;
    border: 1px solid $border-color;

    &.color-green {
      border-color: $green;
      color: $green;
    }

    &.color-danger {
      border-color: $danger;
      color: $danger;
    }
  }
}
