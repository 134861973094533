
          @import "@/styles/variables.scss";
        
.daily-summary-wrapper {
  position: fixed;
  top: 0;
  left: 100vw;
  width: 100%;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.5rem;

  &.open {
    left: 0;
    transition: left 0.3s;
    transition-timing-function: ease-in-out;
  }

  > div {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    max-width: $max-width;
    margin: 0 auto;

    .popup-title {
      flex-grow: 0;

      display: flex;
      background-color: $gray-100;
      height: 50px;

      .icon {
        padding: 1rem;
        align-self: center;
      }

      .text {
        flex-grow: 1;
        align-self: center;
      }
    }

    .popup-content {
      flex-grow: 1;
      position: relative;

      > div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        padding-top: 1rem;

        scrollbar-width: thin;
        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-track {
          background-color: #f8f8f8;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #d4d4d4;
          border-radius: 4px;
        }

        .month-block {
          margin-bottom: 2rem;
          padding: 0.5rem;
          .month-title {
            font-size: 18px;
            margin-bottom: 1rem;
            padding: 0 1rem;
          }

          .day-0 {
            color: $red-color;
          }

          .day-6 {
            color: $blue;
          }

          .days {
            margin-bottom: 0.5rem;
            > div {
              display: inline-block;
              width: calc(100% / 7);
              font-size: 14px;
              text-align: center;
            }
          }

          .month-content {
            .week-block {
              margin-bottom: 0.5rem;
              &:last-child {
                margin-bottom: 0;
              }
              &.first-week {
                text-align: right;
              }

              &.last-week {
                text-align: left;
              }
              .day-item {
                display: inline-flex;
                width: calc(100% / 7);
                font-size: 14px;
                height: 55px;
                position: relative;
                justify-content: center;

                .today-label {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  text-align: center;
                  z-index: 2;
                  font-size: 11px;
                }

                &.in-valid {
                  opacity: 0.4;
                }

                .day {
                  text-align: center;
                  margin-bottom: 0.25rem;
                  align-self: center;
                  width: 30px;
                  height: 30px;
                  display: inline-flex;
                  justify-content: center;

                  > span {
                    align-self: center;
                  }

                  &.holiday {
                    color: $red-color !important;
                  }

                  &.selected {
                    background-color: #333;

                    border-radius: 50%;
                    color: $white !important;
                  }
                }

                .count {
                  text-align: center;
                  position: absolute;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  font-size: 12px;
                  color: $green;
                }
              }
            }
          }
        }
      }
    }
  }
}
