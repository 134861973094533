
          @import "@/styles/variables.scss";
        
.day-weather-wrapper {
  width: 100%;
  display: flex;
  height: 100%;
  max-width: 120px;
  > div {
    flex: 1 1 0;
    font-size: 10px;
    text-align: center;
    align-self: center;
    line-height: 0;

    .sunny {
      font-size: 14px;
      color: $yellow;
      position: relative;
      top: 1px;
    }

    .cloudy {
      color: $gray-600;
    }

    .rainy {
      color: $warning;
      font-size: 12px;
      position: relative;
      top: 2px;
    }
  }
}
