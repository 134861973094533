
          @import "@/styles/variables.scss";
        
.my-customer-service-list-wrapper {
  .page-content {
    padding-top: 2rem;
    padding-bottom: 2rem;

    @media (max-width: 768px) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .empty-list {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    > div {
      align-self: center;

      .icon {
        font-size: 30px;
        text-align: center;
        margin-bottom: 1rem;
      }
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    border-radius: 5px;

    @media (max-width: 768px) {
      padding: 0;
      margin: 0 1rem;
    }

    li {
      display: flex;
      border: 1px solid $border-color;
      border-bottom: none;
      background-color: $white;

      @media (max-width: 768px) {
        display: block;
        padding-bottom: 0.75rem;
        border: none;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }

      &:last-child {
        border-bottom: 1px solid $border-color;

        @media (max-width: 768px) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
      }

      > div {
        align-self: center;
      }

      .subject {
        flex-grow: 1;
        margin-right: 1rem;
        padding: 1rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 768px) {
          margin-right: 0;
          padding: 0.75rem 0.5rem 0rem;
        }

        > a {
          text-decoration: none;
          color: $link-color;

          &:active {
            color: $link-color;
          }
        }
      }

      .others {
        display: flex;
        > div {
          align-self: center;
        }

        @media (max-width: 768px) {
          font-size: 12px;
        }

        .creation-date {
          min-width: 140px;
          text-align: right;

          @media (max-width: 768px) {
            flex-grow: 1;
            text-align: left;
            margin-left: 0.5rem;
          }
        }

        .answered {
          margin-right: 1rem;
          @media (max-width: 768px) {
            display: inline-block;
            margin-right: 0.5rem;
          }

          > span {
            display: inline-block;
            padding: 4px 8px;
            background-color: $gray-200;
            border-radius: 5px;
            min-width: 80px;
            text-align: center;

            @media (max-width: 768px) {
              min-width: 60px;
            }
          }

          &.done {
            > span {
              background-color: $yellow;
            }
          }
        }
      }
    }
  }
}
