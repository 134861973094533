
          @import "@/styles/variables.scss";
        
.edit-user-wrapper {
  .language {
    display: none;
  }

  .location {
    display: none;
  }

  form {
    padding: 0;
  }
}
