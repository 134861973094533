
          @import "@/styles/variables.scss";
        
.navigator-wrapper {
  width: 100%;
  height: 100%;
  padding: 0 1rem 4px;
  ul {
    width: 100%;
    height: 100%;
    align-self: center;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    > li {
      height: 100%;
      line-height: 100%;

      a {
        height: 100%;
        width: 60px;
        display: flex;
        text-decoration: none;
        text-align: center;
        color: $black;
        font-size: 12px;

        > div {
          width: 100%;
        }

        .icon {
          margin-bottom: 2px;
          font-size: 12px;
          > span {
            display: inline-flex;
            width: 24px;
            height: 24px;
            background-color: $gray-100;
            justify-content: center;
            border-radius: 5px;

            i {
              align-self: center;
            }
          }
        }

        &:active {
          color: $black;
        }

        > div {
          align-self: center;
        }
      }

      &.selected {
        a {
          .icon {
            > span {
              background-color: $yellow;
            }
          }
        }
      }
    }
  }
}
