
          @import "@/styles/variables.scss";
        
.tee-info-popup-wrapper {
  position: fixed;
  top: 0;
  left: 100vw;
  width: 100%;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.5rem;

  &.open {
    left: 0;
    transition: left 0.3s;
    transition-timing-function: ease-in-out;
  }

  > div {
    background-color: $white;
    width: 100%;
    height: 100%;
    border: 1px solid $border-color;
    position: relative;
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    border-radius: 5px;

    .exit-button {
      text-align: right;
      position: absolute;
      top: 23px;
      right: 1rem;
    }

    .title {
      font-weight: bold;
      display: flex;

      background-color: $white;
      border-radius: 5px 5px 0 0;
      border-bottom: 1px solid $border-color;

      > div {
        align-self: center;
        padding: 0.75rem 0;
      }

      .icon {
        width: 50px;
        text-align: center;
        height: 100%;
        display: inline-flex;
        justify-content: center;
        > span {
          align-self: center;
        }
      }

      .region-name {
        margin-right: 0.75rem;
      }

      .region-info {
        display: flex;
        .region-count {
          font-size: 14px;
          margin-top: 0.125rem;
        }

        .slash {
          margin: 0 0.25rem;
          font-size: 14px;
          margin-top: 0.125rem;
        }

        .region-date {
          font-size: 14px;
          margin-top: 0.125rem;
        }
      }
    }
  }

  > div {
    .tee-info-list {
      flex-grow: 1;
      position: relative;
      > div {
        display: flex;
        flex-direction: column;
        height: 100%;

        .info-content {
          flex-grow: 1;
          position: relative;
          font-size: 14px;

          > div {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow-y: auto;
            scrollbar-width: thin;
            background-color: $gray-100;
            padding: 10px;

            &::-webkit-scrollbar {
              width: 8px;
            }
            &::-webkit-scrollbar-track {
              background-color: #f8f8f8;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #d4d4d4;
              border-radius: 4px;
            }

            ul {
              margin: 0;
              padding: 0px;
              list-style: none;
              width: 100%;
              border: 1px solid $border-color;

              li {
                display: flex;
                border-bottom: 1px solid $border-color;
                border-radius: 0;
                padding: 0 5px;
                background: $white;
                height: 55px;

                &:last-child {
                  border-bottom: none;
                }

                &:last-child {
                  margin-bottom: 0;
                }

                > div {
                  align-self: center;

                  &.tee-time {
                    flex-grow: 0;
                    width: 50px;
                    font-weight: bold;
                    color: $link-color;
                    padding-left: 10px;
                  }

                  &.course {
                    flex-grow: 1;
                    font-size: 14px;
                  }

                  &.green-fee {
                    white-space: nowrap;
                    text-align: right;
                    font-size: 14px;
                    width: 60px;

                    > div {
                      display: block;
                      margin-bottom: 0.25rem;
                      &:last-child {
                        margin-bottom: 0;
                      }

                      .char {
                        display: inline-flex;
                        font-size: 10px;
                        width: 15px;
                        height: 15px;
                        font-family: NotoSans, sans-serif;

                        border-radius: 50%;
                        line-height: 0;
                        justify-content: center;
                        margin-right: 4px;

                        > span {
                          align-self: center;
                          line-height: 0;
                          color: $white;
                        }

                        &.non-member {
                          background-color: $gray-700;
                        }
                      }
                    }
                  }

                  &.caddy {
                    min-width: 36px;
                    text-align: right;
                  }

                  &.link {
                    color: $link-color;
                    > span {
                      display: inline-block;
                      padding: 0.5rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
