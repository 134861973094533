
          @import "@/styles/variables.scss";
        
.club-review-wrapper {
  position: fixed;
  top: 0;
  left: 100vw;
  width: 100%;
  bottom: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 0.5rem;

  &.open {
    left: 0;
    transition: left 0.3s;
    transition-timing-function: ease-in-out;
  }

  > div {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    max-width: $max-width;
    margin: 0 auto;

    .popup-title {
      flex-grow: 0;
      height: 50px;
      display: flex;
      background-color: $gray-100;

      > div {
        align-self: center;
      }

      .icon {
        padding: 1rem;
        align-self: center;
      }

      .text {
        flex-grow: 1;
        align-self: center;
      }

      .close-button {
        padding: 0 1rem;
      }
    }

    .popup-content {
      flex-grow: 1;
      position: relative;

      > div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        padding: 1rem;

        scrollbar-width: thin;
        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-track {
          background-color: #f8f8f8;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #d4d4d4;
          border-radius: 4px;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            margin-bottom: 0.5rem;
            .title {
              font-size: 18px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              a {
                text-decoration: none;
              }
            }

            .info {
              font-size: 12px;
              display: flex;
              margin: 0.25rem 0;
              > span {
                &.blogger {
                  flex-grow: 1;
                  text-align: left;
                }
                &.date {
                  text-align: right;
                }
              }
            }

            .description {
              font-size: 14px;
              padding: 0.5rem;
              color: $gray-600;
            }
          }
        }
      }
    }

    .paging {
      flex-grow: 0;
      padding: 1rem;
      text-align: center;
      > button {
        margin: 0 0.25rem;
      }
    }
  }
}
