
          @import "@/styles/variables.scss";
        
.withdrawal-wrapper {
  .msg {
    text-align: center;
  }
  p {
    line-height: 160%;
    word-break: break-all;
    padding: 0 1rem;
    text-align: justify;

    &.sns-message {
      font-size: 14px;
      padding: 1rem;
      text-align: left;
      border: 1px solid $border-color;
      border-radius: 5px;
      margin: 0;
    }

    .path {
      display: inline-block;
      background-color: $gray-200;
      padding: 3px 6px;
      border-radius: 5px;
      line-height: 100%;
    }

    .gt {
      margin: 0 0.5rem;
    }
  }
  .buttons {
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
  }
}
