
          @import "@/styles/variables.scss";
        
.oauth-register-wrapper {
  .logo {
    @media (max-width: 768px) {
      padding-top: 1rem;
    }
  }

  .skip-link {
    text-align: center;
    margin-bottom: 1rem;
  }

  .no-data {
    text-align: center;
  }

  .info {
    max-width: 620px;
    text-align: center;
    margin: 1rem auto;

    @media (max-width: 768px) {
      max-width: 400px;
      text-align: left;
    }
  }

  .form {
    padding: 0;

    @media (max-width: 768px) {
      max-width: 300px;
    }
    margin: 0 auto;

    .title {
      text-align: center;
      margin-bottom: 1rem;
    }

    p {
      max-width: 300px;
      margin: 0 auto 0.5rem;
      text-align: center;
    }
  }

  .register-content {
    margin: 0 auto;
    background-color: $white;
    position: relative;
    border-radius: 8px;

    .social {
      .text {
        margin-left: -0.25rem;
      }
    }

    .language,
    .date {
      display: none;
    }
  }

  .block {
    max-width: 400px;
    margin: 0 auto;
  }

  .agree-block {
    margin: 1rem auto;
    max-width: 400px;

    > div {
      display: block;
    }

    @media (max-width: 768px) {
      padding: 1rem 1rem 0.75rem;
      border-radius: 5px;
      margin: 1rem -1rem;
      background-color: $gray-100;
    }

    &.first {
      margin-top: 2rem;
      @media (max-width: 768px) {
        margin-top: 2rem;
      }
    }

    .info {
      color: $gray-600;
      font-size: 14px;
      margin-bottom: 0.5rem;
      text-align: left;

      @media (max-width: 768px) {
        margin-bottom: 0;
      }
    }

    .message {
      font-size: 14px;
      padding: 0 0.25rem;
      margin-bottom: 0.5rem;
      @media (max-width: 768px) {
        margin-top: 0.5rem;
        margin-bottom: 0;
      }
    }

    .agree {
      padding: 0 0.25rem;

      @media (max-width: 768px) {
        margin-top: 0.5rem;
      }
    }
  }

  .tel {
    display: flex;

    input {
      text-align: center;
    }

    .tel-1 {
    }

    .tel-2 {
    }

    .tel-3 {
    }
  }

  .modal-footer {
    text-align: right !important;
    margin-top: 1rem !important;
  }

  input {
    border: none;
    border-bottom: 1px solid #666;
    border-radius: 0;
    padding: 12px;
    height: auto !important;
    font-size: 16px;
  }

  .vendor {
    display: inline-block;
    margin-right: 0.5rem;

    &.kakao {
      background-color: $kakao-color;
      display: inline-block;
      text-transform: uppercase;
      font-size: 12px;
      padding: 3px;
      border-radius: 3px;
      font-weight: bold;
    }

    &.naver {
      background-color: $naver-color;
      display: inline-block;
      text-transform: uppercase;
      font-size: 12px;
      padding: 3px;
      border-radius: 3px;
      font-weight: bold;
      color: $white;
    }
  }

  .links {
    margin-top: 2rem;
    text-align: center;

    > a {
      color: $blue;

      &:visited {
        color: $blue;
      }
    }
  }

  .agree-content {
    max-height: 460px;
    overflow-y: auto;
    margin-top: 1rem;
    background-color: $gray-100;
    padding: 1rem;

    @media (max-height: 700px) {
      max-height: 460px;
    }

    @media (max-height: 600px) {
      max-height: 360px;
    }

    @media (max-height: 500px) {
      max-height: 300px;
    }
  }
}
